<template>
  <div class="modal topup-modal">
    <div 
      class="overlay" 
      @click="modalsStore.closeTopupModal()"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              src="./../assets/img/close.svg" 
              class="close" 
              @click="modalsStore.closeTopupModal()"
            >
            <div class="form-fields">
              <form @submit="onSubmit" class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="desc">
                      <b>{{ $t('ADDING FUNDS TO BALANCE') }}</b>
                    </div>
                  </div>
                  <div class="topup-form">
<!--                    <div class="desc">-->
<!--                      {{ $t('PAYMENT METHOD:') }}-->
<!--                    </div>-->
<!--                    <div class="desc small">-->
<!--                      {{ $t('CREDIT CARD') }}-->
<!--                    </div>-->

                    <label class="input-container">
                      <div class="desc">
                        {{ $t('First name') }}
                      </div>
                      <input 
                        v-model="name"
                        v-bind="nameAttr"
                        type="text"
                        required
                        :placeholder="$t('First name')"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Last name') }}
                      </div>
                      <input 
                        v-model="surname"
                        v-bind="surnameAttr"
                        type="text"
                        required
                        :placeholder="$t('Last name')"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Email') }}
                      </div>
                      <input
                          v-model="email"
                          v-bind="emailAttr"
                          type="email"
                          required
                          :placeholder="$t('Email')"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Phone') }}
                      </div>
                      <input 
                        v-model="phone"
                        v-bind="phoneAttr"
                        type="tel"
                        required
                        :placeholder="$t('Phone')"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Country') }}
                      </div>
                      <select required v-model="country" v-bind="countryAttr">
                        <option 
                          value="" 
                          disabled 
                          selected
                        >
                          {{ $t('Countries') }}
                        </option>
                        <option 
                          v-for="item in countries" 
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ $t(item.title) }}
                        </option>
                      </select>
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('City') }}
                      </div>
                      <input
                        v-model="city"
                        v-bind="cityAttr"
                        type="text" 
                        :placeholder="$t('City')"
                        required
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Address') }}
                      </div>
                      <input 
                        v-model="address"
                        v-bind="addressAttr"
                        type="text" 
                        :placeholder="$t('Address')"
                        required
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Post Code') }}
                      </div>
                      <input 
                        v-model="zip"
                        v-bind="zipAttr"
                        type="text" 
                        :placeholder="$t('Post Code')"
                        required
                      >
                    </label>
<!--                    <label class="input-container">-->
<!--                      <div class="desc">-->
<!--                        {{ $t('Enter amount') }}-->
<!--                      </div>-->
<!--                      <input -->
<!--                        v-model="amount"-->
<!--                        type="text" -->
<!--                        :placeholder="$t('Enter amount')"-->
<!--                        @input="allowOnlyNumbers"-->
<!--                        required-->
<!--                      >-->
<!--                      <div class="desc desc-curr">-->
<!--                        {{ $parent.currency }}-->
<!--                      </div>-->
<!--                    </label>-->
                    <div class="cta-container">
                      <input v-model="agree" v-bind="agreeAttr" v-show="false" type="checkbox">
                      <div @click="agree = !agree" class="checkbox"></div>
                      <div class="desc">
                        {{ $t("By signing up you agree to our") }}
                        <a class="link" @click="staticStore.goToPage('terms')">{{ $t("terms") }}</a>
                        {{ $t("and") }}
                        <a class="link" @click="staticStore.goToPage('privacy')">{{ $t("privacy policy") }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cart-bottom">
                  <div v-if="modalsStore.topUpAmount" class="desc">
                    {{ $t('You will get') }}:
                    <b>{{ modalsStore.topUpAmount * settings.points_conversion_rate  }} {{$t('credits')}}</b>
                  </div>
                  <button 
                    class="button blue"
                    type="submit"
                    :disabled="!meta.touched || !meta.valid"
                  >
                    <span>{{ $t('Pay') }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div 
                    v-if="$parent.error" 
                    class="error-desc desc red"
                  >
                    {{ $t($parent.error) }}
                  </div>
                </transition>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {useForm} from "vee-validate";
import * as yup from 'yup';

import { getModalsStore } from './../stores/modals';
import{getStaticStore} from './../stores/static'
import {getSettingsStore} from "@/stores/settings";
import {storeToRefs} from "pinia";
import axios from "@/axios";
import {getProfileStore} from "@/stores/profile";
import {onMounted} from "vue";
const profileStore = getProfileStore();
const {profile} = profileStore;


const {handleSubmit, meta, defineField} = useForm({
  validationSchema: yup.object({
    name: yup.string().required(),
    surname: yup.string().required(),
    phone: yup.string().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    address: yup.string().required(),
    postCode: yup.string().required(),
    agree: yup.boolean().oneOf([true])
  }),
  initialValues: {
    name: profile?.name || '',
    surname: profile?.surname || '',
    phone: profile?.phone || '',
    country: profile?.country_id,
    address: profile?.address,
    postCode: profile?.zip,
    email: profile?.email || '',
    agree: false
  }
});

const [name, nameAttr] = defineField('name')
const [surname, surnameAttr] = defineField('surname')
const [phone, phoneAttr] = defineField('phone')
const [email, emailAttr] = defineField('email')
const [country, countryAttr] = defineField('country')
const [city, cityAttr] = defineField('city')
const [address, addressAttr] = defineField('address')
const [zip, zipAttr] = defineField('postCode')
const [agree, agreeAttr] = defineField('agree')

const onSubmit = handleSubmit(async (values) => {
  try {
    const data = {...values};

    if (modalsStore.pack) {
      data['pack_id'] = modalsStore.pack.id;
    } else {
      data['amount'] =  +((modalsStore.topUpAmount));
    }

    const {data: {
      redirect_url
    }} = await axios.post(process.env.VUE_APP_API + 'deposit/checkout', data);

    window.location.href = redirect_url;
  } catch (e) {
    console.log(e);
  }
});


const modalsStore = getModalsStore();
const staticStore = getStaticStore();
const settingsStore = getSettingsStore();

const {settings, fetchCountries} = settingsStore;
const {countries} = storeToRefs(settingsStore);

onMounted(() => {
  if (!countries || !countries?.length) {
    fetchCountries()
  }
})
</script>